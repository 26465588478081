table {
  border-radius: 8px;
}

thead {
  tr {
    background-color: rgba(0, 0, 0, 0.1) !important;
  }
  th {
    border: none !important;
    color: rgba(0, 0, 0, 1);
    vertical-align: middle !important;
  }
}

tbody {
  td {
    border: none !important;
    vertical-align: middle !important;
  }
}

.table-striped tbody tr:nth-of-type(even) {
  background-color: rgba(0, 0, 0, 0.05) !important;
}

.table-striped tbody tr:nth-of-type(odd) {
  background-color: transparent !important;
}

.dropdown-menu.show {
    z-index: 1051 !important;
}

.query-builder-container {
    .rule--header {
        .svg-inline--fa{
            margin-right: 0;
        }
    }
    button {
        border-radius: 0.2rem !important;
        &.btn-primary {
            .svg-inline--fa{
                margin-right: 5px;
            }
        }
    }
    .btn-group {
        .btn:not(:last-child) {
            margin-right: 0.5rem !important;
        }
    }
    input {
        border-radius: 0.2rem;
        font-size: 0.9375rem;
        height: calc(1.90625rem + -3px);
    }
    .dropdown{
        button {
            line-height: inherit !important;
            display: inline-block;
            font-weight: 400;
            color: #112920;
            text-align: center;
            vertical-align: middle;
            -webkit-user-select: none;
            user-select: none;
            background-color: transparent;
            border: 1px solid transparent;
            padding: 0.15rem 0.5rem !important;
            font-size: 0.75rem;
            line-height: 1.5;            border-radius: 0.1rem;
            transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
            color: #fff;
            background-color: #20c997;
            border-color: #20c997;
        }
    }
    .group-or-rule {
        border: 1px solid #dee2e6 !important;
    }
}

.dropdown-menu.show {
    button.disabled.dropdown-item {
        display: none !important;
    }
}
body {
  background-size: cover;
  background-position-y: 0;
  background-color: #f2f2f2;
  letter-spacing: 0.25px;
}

.min-width-150 {
  min-width: 150px !important;
}

.brand-img {
  border-radius: 0.5rem;
}

.text-underline {
  text-decoration: underline !important;
}

.max-width-20 {
  max-width: 20px;
}

.min-width-50 {
  min-width: 50px;
}

.width-150 {
  width: 150px;
}

.max-width-150 {
  max-width: 150px;
}

.min-width-300 {
  min-width: 300px;
}

.min-width-lg {
  min-width: 1200px;
}

.box-shadow-none {
  box-shadow: none !important;
}

.min-vh-20 {
  min-height: 20vh !important;
}

.min-height-50 {
  min-height: 50%;
}

.max-height-100 {
  max-height: 100px;
}

.height-100 {
  height: 100%;
}

.min-height-100-px {
  min-height: 100px;
}

.bg-transparent {
  background: transparent !important;
}

.font-size-75 {
  font-size: 0.75rem;
}

.font-size-85 {
  font-size: 0.85rem;
}

.font-size-95 {
  font-size: 0.95rem;
}

.font-size-65 {
  font-size: 0.65rem;
}

.font-size-1 {
  font-size: 1rem;
}

.font-size-1-1 {
  font-size: 1.1rem;
}

.font-size-1-2 {
  font-size: 1.2rem;
}

.align-content-evenly {
  align-content: space-evenly;
}

.word-break-all {
  word-break: break-all;
}

.max-width-200 {
  max-width: 200px;
}

.max-width-250 {
  max-width: 150px;
}

.max-width-100 {
  max-width: 100px;
}

.width-170 {
  width: 170px;
}

.width-250 {
  width: 250px;
}

.upper-close {
  position: absolute;
  right: -8px;
  top: -12px;
}

.width-100-px {
  width: 100px;
}

.width-100 {
  width: 100%;
}

.z-index-2 {
  z-index: 2;
}

.overflow-x-visible {
  overflow-x: visible !important;
}

.overflow-x-hidden {
  overflow-x: hidden !important;
}

.absolute-right {
  position: absolute;
  right: 0;
}

.bg-light {
  background-color: #f8f9fa !important;
}

.conflict i {
  color: #dc3545 !important;
}

.setting-switch {
  .custom-control-label {
    padding-top: 2px;
  }
}

.overflow-x-auto {
  overflow-x: auto;
}

.overflow-y-hidden {
  overflow-y: hidden;
}

.font-500 {
  font-weight: 500;
}

.font-300 {
  font-weight: 300;
}

.overflow-x-scroll {
  overflow-x: scroll;
}

.text-xl {
  font-size: 2.875rem !important;
}

.outline-none,
.outline-none:focus {
  outline: none !important;
}

.border-radius-0 {
  border-radius: 0;
}

.react-bootstrap-table {
  overflow-x: auto;
}

.cursor-default {
  cursor: default !important;
}

.z-index-n1 {
  z-index: -1;
}

.form-group {
  .form-control {
    height: 40px;
  }
}

.week-selector {
  height: 32.5px;
  span {
    border-radius: $border-radius-default;
    background-color: rgba(118, 118, 128, 0.12);
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
}

.text-link {
  color: #3b7ddd;
  cursor: pointer;
  &:hover {
    text-decoration: underline;
  }
}

.back-arrow {
  padding: 6px 10px;
}

.work-performed-cards {
  .card {
    min-width: 130px;
    box-shadow: 0px -2px 16px 0px rgba(0, 0, 0, 0.2);
    border-radius: $border-radius-default;
  }
}

.box-shadow-lg {
  box-shadow: 0px -2px 16px 0px rgba(0, 0, 0, 0.1);
}

.box-shadow-darker {
  box-shadow: 0px -2px 16px 0px rgba(0, 0, 0, 0.2);
}

.border-radius-default {
  border-radius: $border-radius-default;
}

.border-radius-md {
  border-radius: $border-radius-md;
}

.border-top-left-radius-0 {
  border-top-left-radius: 0;
}

.currency {
  letter-spacing: 0.5px;
}

.note {
  background-color: rgba(253, 244, 166, 1);
  border-radius: $border-radius-default;
}

.carousel-caption {
  h3 {
    border-radius: $border-radius-default;
    background-color: #ffffff57;
  }
}

.fa-chevron-down {
  transition: transform linear 200ms;
  &.rotate {
    transform: rotate(-180deg);
  }
}

.react-transform-wrapper,
.react-transform-component {
  width: 100% !important;
}

.react-transform-component {
  min-height: 350px;
}

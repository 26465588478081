input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  &:not(.login-input) {
    -webkit-box-shadow: 0 0 0px 1000px #f4f7fc inset;
    transition: background-color 5000s ease-in-out 0s;
  }
}

input.form-control {
  background-color: rgba(118, 118, 128, 0.12) !important;
  &:not(.login-input) {
    border-radius: $border-radius-default !important;
  }
}

.search::before {
  content: "";
  background-image: url("../img/glass_icon.svg");
  background-repeat: no-repeat;
  background-position: center;
  background-size: 16px 16px;
  width: 16px;
  height: 16px;
  margin-right: 8px;
}

.form-control-redesign {
  border: none;
  background-color: transparent !important;
  border-radius: 0 !important;
  border-bottom: 1px solid #263238;
  padding: 0;
  color: #6c757d;
  font-size: 0.9375rem;
  font-weight: 400;
  line-height: 1.5;
  display: block;
  width: 100%;
  height: 40px;
  border-color: #263238;
  box-shadow: 0 0.5px 0 0 #263238;
  outline: none !important;
}

select:disabled {
  opacity: 1 !important;
}

.react-select {
  height: 40px;
  color: #6c757d;
  border-color: #263238;
  border-bottom: 1px solid #263238;
  box-shadow: 0 0.5px 0 0 #263238;

  .react-select__single-value {
    color: #6c757d !important;
  }
  .react-select__value-container--has-value,
  .react-select__value-container {
    padding-left: 0;
  }
}
